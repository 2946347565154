<template>
  <div class="iss-main-index">
    <div class="iss-main-grid" ref="mainRef">
      <div class="iss-search-wrap">
        <search-form ref="searchFormRef" :items="items" @fnSearch="handleFnSearch" />
      </div>
      <div class="iss-grid-wrap mt-16">
<!--      allow-selection  -->
        <grid
          ref="gridRef"
          :columns="columns"
          :code="$route.path"
          :url="url"
          :search="search"
          :btn-operation="btnOperation"
          :scroll="{ x: 2000, y: gridHeight }"
        >
          <template #invitationCodeName="{ record }">
            <div class="nameStyle">
              <div class="cursor-p mr-5" @click="goDetail(record)">
                <a-avatar v-if="record.avatar" :src="setAvatar(record.avatar)" :size="30" />
                <a-avatar ::size="30" v-else>
                  <template #icon><UserOutlined /></template>
                </a-avatar>
              </div>

              <div class="cursor-p primary-text" @click="goDetail(record)">
                {{ record.userName }}
              </div>
            </div>

          </template>

<!--          <template #codeSettingStatus="{ record }">-->
<!--            <div v-if="record.codeSettingStatus === 1">启用</div>-->
<!--            <div v-if="record.codeSettingStatus === 0">禁用</div>-->
<!--          </template>-->

          <template #codeStatus="{ record }">
            <div v-if="record.codeStatus === 2">已过期</div>
            <div v-if="record.codeStatus === 1">进行中</div>
            <div v-if="record.codeStatus === 0">未开始</div>
          </template>

          <template #status="{ record }">
            <a-switch
                :checked="record.status"
                checked-children="启用"
                un-checked-children="禁用"
                @click="handleClickSwitch(record)"
            />
          </template>

          <template #beginTime="{ record }">
            <div>{{record.beginTime}}-{{record.endTime}}</div>
          </template>

<!--          <template #beginTime="{ record }">-->
<!--            <div>{{record.beginTime}}-{{record.endTime}}</div>-->
<!--          </template>-->

          <template #operation="{ record }">
            <operation :options="options" :record="record">
              <template #goData>
                <div class="primary-text cursor-p">数据</div>
              </template>
              <template #more>
                <div class="primary-text cursor-p">
                  <a-dropdown>
                    <a class="ant-dropdown-link" @click.prevent> 操作 </a>
                    <template #overlay>
                      <a-menu @click="clickOptions($event, record)">
                        <a-menu-item
                          v-for="item in itemOption"
                          :key="item.value"
                        >
                          <span class="padding-10">{{ item.label }}</span>
                        </a-menu-item>
                      </a-menu>
                    </template>
                  </a-dropdown>
                </div>
              </template>
            </operation>
          </template>
        </grid>
      </div>
    </div>
  </div>
  <add-modal v-model:visible="visible" :init-value="activeItem" @fnOk="handleFnok"></add-modal>
</template>

<script>
import {reactive, ref, toRaw, toRefs} from 'vue';
import SearchForm from '@/components/searchForm';
import Grid from '@/components/grid';
import Operation from '@/components/operation';
import marketingApi from '@/api/marketing.js';
import { useRouter } from 'vue-router';
import {Dropdown, Menu, Modal, Avatar, message, Switch,} from 'ant-design-vue';
import addModal from './components/addModal.vue';
import { setAvatar } from '@/utils';
import {
  UserOutlined,
} from '@ant-design/icons-vue';

export default {
  components: {
    SearchForm,
    Grid,
    Operation,
    addModal,
    ADropdown: Dropdown,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    AAvatar: Avatar,
    ASwitch: Switch,
    UserOutlined,
  },
  setup() {
    const gridRef = ref();
    const searchFormRef = ref();
    const state = reactive({
      search: {},
      totalRegistrations: 0,
      totalActivates: 0,
      totalSubscriptions: 0,
      visible: false,
      activeItem: {},
    });

    const router = useRouter();

    const clickOptions = ({ key }, record) => {
      console.log(key, record);
      switch (key) {
        case 'delete':
          Modal.confirm({
            title: '确定要删除该条数据吗?',
            onOk: () => {
              marketingApi.deleteInvit('resources:delete', { id: record.id }).then(() => {
                message.success('操作成功');
                gridRef.value.refreshGrid();
              })
            },
          });
          break;
        case 'disable':
          Modal.confirm({
            title: '确定要禁用该邀请码吗?',
            onOk: () => {
              console.log('OK');
            },
          });
          break;
        case 'edit':
          // 调取查询接口
          state.activeItem = record
          console.log('state.activeItem', state.activeItem)
          state.visible = true;
          break;
      }
    };
    return {
      gridRef,
      searchFormRef,
      ...toRefs(state),
      gridHeight: document.body.clientHeight - 260,
      url: marketingApi.getInvitationCodePage,
      itemOption: [
        // { value: 'delete', label: '删除' },
        { value: 'edit', label: '编辑' },
        // { value: 'disable', label: '禁用' },
      ],
      items: [
        {
          key: 'status',
          label: '启用状态',
          type: 'select',
          dataset: [
            { value: 1, label: '启用' },
            { value: 0, label: '禁用' },
          ],
        },
        {
          key: 'codeTimeStatus',
          label: '生效状态',
          type: 'select',
          dataset: [
            { value: 0, label: '未开始' },
            { value: 1, label: '进行中' },
            { value: 2, label: '已过期' },
          ],
        },
        {
          key: 'date',
          timeKey: ['beginTime', 'endTime'],
          label: '日期范围',
          type: 'rangePicker',
        },
        { key: 'condition', label: '邀请码名称' },
      ],
      columns: [
        {
          title: '序号',
          width: 80,
          ellipsis: true,
          customRender: ({ index }) => `${index + 1}`,
          align: 'center',
        },
        {
          dataIndex: 'invitationCodeName',
          title: '邀请码名称',
          width: 120,
          ellipsis: true,
        },
        { dataIndex: 'invitationCode', title: '邀请码', width: 110, ellipsis: true },
        {
          dataIndex: 'status',
          title: '启用状态',
          width: 100,
          slots: { customRender: 'status' },
        },
        {
          dataIndex: 'invitationCodeName',
          title: '使用人',
          width: 150,
          ellipsis: true,
          slots: { customRender: 'invitationCodeName' },
        },
        {
          dataIndex: 'createTime',
          title: '创建时间',
          width: 120,
        },
        {
          dataIndex: 'codeStatus',
          title: '生效状态',
          width: 100,
          slots: { customRender: 'codeStatus' },
        },
        {
          dataIndex: 'beginTime',
          title: '有效期',
          width: 250,
          ellipsis: true,
          slots: { customRender: 'beginTime' },
        },
        {
          dataIndex: 'orderNum',
          title: '邀请人数',
          width: 120,
          ellipsis: true,
        },
        {
          dataIndex: 'id',
          title: '操作',
          fixed: 'right',
          width: 100,
          slots: { customRender: 'operation' },
        },
      ],
      options: [
        {
          type: 'more',
          slotName: 'more',
          title: '操作',
          permission: 'user:more',
          fnClick: ({ id }) => {
            console.log(id);
          },
        },
        {
          type: 'goData',
          slotName: 'goData',
          title: '数据',
          permission: 'user:goData',
          fnClick: ({ id, userId }) => {
            router.push({
              name: 'invitationDetail',
              query: {
                id,
                userId,
              },
            });

          },
        },
      ],
      btnOperation: [
        {
          type: 'add',
          label: '新增',
          permission: 'contact:export',
          fnClick: () => {
            state.visible = true;
            state.activeItem = {}
          },
        },
      ],
      clickOptions,
      setAvatar,
      handleFnSearch: value => {
        state.search = value;
      },
      goDetail: ({ userId }) => {
        router.push({
          name: 'userDetail',
          params: {
            userId,
          },
        });
      },

      handleFnok: (value) =>{
        state.visible = value;
        gridRef.value.refreshGrid();

      },
      handleClickSwitch(record) {
        let obj = record
        obj.status = !obj.status
        let parmas = {...obj}

        marketingApi.updateInvit('', toRaw(parmas)).then((res) => {
         if (res){
           message.success('操作成功');
           gridRef.value.refreshGrid();
         }

        });

      },
    };
  },
};
</script>

<style lang="less" scoped>
.iss-main-index {
  max-height: calc(100vh - 56px);
  overflow-y: auto;
}
.nameStyle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}


</style>
